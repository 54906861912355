"use client";
import { withSentry } from "@sentry/remix";
import { ThemeProvider, useTheme, PreventFlashOnWrongTheme } from "remix-themes";

import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useLoaderData,
  Link,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";

import { getUser, themeSessionResolver } from "./session.server";
// import tailwindStylesheetUrl from '~/style.css?url';

// // Test this
// const tailwindStylesheetUrl = '~/styles/tailwind.css?url';

import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import { AppProvider } from "./context/app-provider";
import { LogError } from "./utils/log";
import { Alert, ArrowLeft } from "./components/Icons";
import { ReaderProvider } from "./context/reader-provider";
import mainStyles from "~/styles/main.css?url";

export const links = () => {
  return [
    { rel: "stylesheet", href: mainStyles },
    { rel: "stylesheet", href: "https://use.typekit.net/jzd8whz.css" },
    { rel: "manifest", href: "/manifest.json" },
  ];
};

export const meta = () => {
  return [
    { charset: "utf-8" },
    { title: "Mindsera" },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "theme-color",
      content: "#fff",
      media: "(prefers-color-scheme: light)",
    },
    {
      name: "theme-color",
      content: "#1f2937",
      media: "(prefers-color-scheme: dark)",
    },
    { name: "mobile-web-app-capable", content: "yes" },
    { name: "robots", content: "noindex" },
    // og: Open Graph
    { property: "og:title", content: "Login to Mindsera" },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://beta.mindsera.com" },
    {
      property: "og:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
    { property: "og:description", content: "" },
    // twitter
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@mindseraHQ" },
    { name: "twitter:creator", content: "@mindseraHQ" },
    { name: "twitter:title", content: "Login to Mindsera" },
    { name: "twitter:description", content: "" },
    {
      name: "twitter:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
  ];
};

export async function loader({ request }) {
  const { getTheme } = await themeSessionResolver(request);

  return json({
    user: await getUser(request),
    theme: getTheme(),
  });
}

function App() {
  const location = useLocation();
  const data = useLoaderData();
  const [theme] = useTheme();

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-5VZGRX3" });
  }, [location]);

  return (
    <html
      lang="en"
      className={`h-full ${data?.user?.betaEnabled ? "bg-white" : "bg-gray-100"} dark:bg-gray-900`}
      data-theme={theme ?? ""}
    >
      <head>
        <Meta />
        <Links />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
      </head>
      <body className={data?.user?.betaEnabled ? "relative h-full bg-white" : "relative h-full"}>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  const is404 = isRouteErrorResponse(error) && error.status === 404;

  const title = is404 ? "404 Page not found" : "Oops! An error has occurred.";
  const message = is404
    ? "This page must be hiding between the lines. Let’s get you back to where you belong—try going back or return to the dashboard."
    : "Could you let us know what happened? Your feedback helps us make things better, and we truly appreciate it. Thank you for helping out!";

  const [userInput, setUserInput] = useState("");
  const [hasSent, setHasSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!is404) {
      LogError(error);
    }
  }, []);

  const sendErrorReport = async () => {
    setIsSending(true);

    const res = await fetch("/api/report", {
      method: "POST",
      body: JSON.stringify({ error, userInput }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();

    if (json.success) {
      setHasSent(true);
      setIsSending(false);
    }
  };

  return (
    <html className="h-full">
      <head>
        <title>{title}</title>
        <Meta />
        <Links />
      </head>
      <body className="relative h-full">
        <div className="relative flex h-full flex-col items-center justify-center rounded-xl bg-white px-10 py-4 text-center dark:bg-gray-800">
          <div className="mb-4 flex h-full max-w-[350px] flex-1 flex-col items-center justify-center gap-3">
            <Alert className="mb-4 text-[57px] text-[#EF4444] md:mb-8" />
            <div className="text-lg font-medium uppercase text-black decoration-1 underline-offset-4 md:text-xl">
              {title}
            </div>
            <div className="text-lg font-light text-msgray-700">{message}</div>
            <div className="mt-6 flex w-full flex-col items-center gap-5 text-black">
              {!is404 &&
                (hasSent ? (
                  <div className="py-10 pb-14 text-xl">Thank you for submitting!</div>
                ) : (
                  <div className="flex w-full flex-col items-center gap-3">
                    <textarea
                      rows={5}
                      className="w-full rounded-xl bg-msgray-50 p-3 text-black placeholder:text-msgray-300 focus:outline-none"
                      placeholder="Describe what happened .."
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      maxLength={1000}
                    />
                    <button
                      disabled={isSending}
                      onClick={sendErrorReport}
                      className="flex w-full flex-1 items-center justify-center gap-3 rounded-xl bg-black p-2.5 text-center text-xl text-white md:p-4"
                    >
                      Send
                    </button>
                    <p className="text-xl font-light text-msgray-950">or</p>
                  </div>
                ))}

              <Link
                to="/"
                className="flex w-full flex-1 items-center justify-center gap-3 rounded-xl bg-black p-2.5 text-center text-xl text-white md:p-4"
              >
                Go to dashboard
                {/* <ArrowLeft className="rotate-180" /> */}
              </Link>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function AppWithProviders() {
  const data = useLoaderData();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">
      <AppProvider>
        <ReaderProvider>
          <App />
        </ReaderProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders);
